import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';


import { withLocalize, Translate } from 'react-localize-redux';
import LoyaltyTranslation from '../../../translations/loyalty.json';
import Video from '../../video/Video.js';


import '../GenericPage.css';
import './Loyalty.css';
import DynamicImg from '../../dynamic_image/DynamicImg.js';
import { getExtension, getBaseName, ctrlVAPI } from '../../../utilities/GlobalConsts';
import Axios from 'axios';
import { ReCaptcha } from 'react-recaptcha-v3';
import {Helmet} from 'react-helmet';


const LoyaltyLinkCredsImage = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/loyalty_link_creds';
const LoyaltyRedeemImage = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/loyalty_redeem';
const PortalDashboardImage = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/portal';


const extraBoyImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/9d75bf6d-extra-life-page-back-boy-1024x576';


class Loyalty extends Component {
    constructor(props) {
        super(props);


        this.props.addTranslation(LoyaltyTranslation);
    }


    state = {
        captchaCompleted: false,
        captchaLoaded: false,
        recaptchaToken: "",
        recaptchaVersion: "BalanceCheck",
        email: '',
        amount: -1
    }


    reloadCaptcha = () => {
        this.recaptcha.execute();
        this.setState({ captchaLoaded: false });
        this.forceUpdate();
    }


    onCaptchaExpired = () => {
        this.expireCaptcha();
    }


    expireCaptcha = () => {
        this.setState({ captchaCompleted: false });
    }


    updateReCaptcha = (recaptchaToken) => {
        this.setState({
            recaptchaToken: recaptchaToken,
        })
    }


    onCaptchaResponse = (response) => {
        //captcha completed, we may proceed to use it here on
        this.completeCaptcha(response);
    }


    completeCaptcha = (response) => {
        //set any states and run any functions with the completed captcha
        this.setState({
            captchaCompleted: !(response === '' || response === undefined),
        });
        this.updateReCaptcha(response);
    }


    htmlRedirect = (href) => {
        window.location.href = href;
    }


    // catch-all binding event for inputs. get key from dom, and save in state
    inputChanged = (event) => {
        // get state key from dom element
        let key = event.target.getAttribute('statekey');
        // get state value from dom element
        let value = event.target.value;


        let newState = {...this.state};
        newState[key] = value;
        this.setState(newState);
    }


    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.submitEmailAddress();
        }
    }


    isValidForm = () => {
        return this.state.email !== '';
    }


    getSubmitButtonClasses = () => {
        let classes = "news-letter-button ";


        if(isBrowser) classes += "email-button-browser-font ";
        else classes += "email-button-mobile-font ";


        if(this.isValidForm()) classes += "enabled-letter-button"
        else classes += "disabled-letter-button"


        return classes;
    }


    submitEmailAddress = async () => {
        if(!this.isValidForm()) return;
        let apiData = {
            recaptcha: this.state.recaptchaToken,
            recaptcha_version: this.state.recaptchaVersion,
            email: this.state.email
        }
        try {
            let result = await Axios.post(ctrlVAPI + "/api/customer/checkPointsBalanceFromEmail", apiData);
            // let result = {data: {success: true, amount:25}}
            if (result.data.success) {
                this.setState({
                    amount: result.data.result.amount
                });
            }
        } catch (err) {
            this.setState({
                amount: -1
            });
            console.error(err);
        }
        this.reloadCaptcha();
    }


    render() {


        const amount = this.state.amount;
        let emailMessage = null;


        if(amount === null) {
            emailMessage = <div className="align-center email-message-font"> <Translate id="message.no-bookings" /> </div>
        } else if(amount < 0) {
            emailMessage = null;
        } else if (amount === 0) {
            emailMessage = <div className="align-center email-message-font">
                <Translate id="message.no-points" />
                <br></br>
                <a href={`${getBaseName()}/booking`} target="_blank" rel="noopener noreferrer">
                    <Translate id="message.book-now" />
                </a>
                <Translate id="message.earn" />
            </div>
        } else if (amount > 0) {
            emailMessage = <div className="align-center email-message-font">
                <React.Fragment><Translate id="message.you-have" /><span className="blue-text">${amount.toFixed(2).toString()}</span> <Translate id="message.available" /></React.Fragment>
            </div>
        }


        return (
            <div className="generic-wrapper-no-padding">


                <Helmet>
                    <title>Loyalty Program | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="Earn and redeem Ctrl V loyalty points, view your game play history and learn everything you never knew you wanted to know about your VR game play!"/>
                    <link rel="canonical" href={`${getBaseName()}/loyalty/`} />
                    <meta property="og:title" content="Loyalty Program | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Earn and redeem Ctrl V loyalty points, view your game play history and learn everything you never knew you wanted to know about your VR game play!" />
                    <meta property="og:url" content={`${getBaseName()}/loyalty/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Earn and redeem Ctrl V loyalty points, view your game play history and learn everything you never knew you wanted to know about your VR game play!" />
                    <meta name="twitter:title" content="Loyalty Program | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="loyalty,points,free,earn" />
                </Helmet>


                <div className={ isBrowser ? "browser-wrapper" : "mobile-wrapper" }>
                    <div className="generic-heading">
                        <Translate id="title.when" />
                    </div>
                    <div className="generic-heading blue-text">
                        <Translate id="title.thank-you" />
                    </div>
                    <div className="generic-heading">
                        <Translate id="title.isnt-enough" />
                    </div>
                    <div className="generic-section">
                        <div className="align-center">
                            <Translate id="sub-titles.sub-title-1" />
                        </div>
                    </div>
                    <div className="generic-section">
                        <div className="align-center">
                            <Translate id="sub-titles.sub-title-2.before" />
                            <span className="blue-text">
                                <Translate id="sub-titles.sub-title-2.blue" />
                            </span>
                            <Translate id="sub-titles.sub-title-2.after" />
                        </div>
                    </div>
                    <hr
                        style={{
                            color: "#FFF",
                            backgroundColor: "#FFF",
                            height: .5
                        }}
                    />
                    <br></br>
                    <div className="align-center">
                        <div>
                            <Translate id="sub-titles.sub-title-3.before" />
                            <br></br>
                            <span className="blue-text"><Translate id="sub-titles.sub-title-3.already" /></span>
                        </div>
                        <div className="email-wrapper">
                            <Translate>
                                {({ translate }) => <input aria-label="Email" type="text" value={this.state.email} statekey="email" onKeyPress={this.handleKeyPress} onChange={this.inputChanged} placeholder={translate('sub-titles.sub-title-3.email')} />}
                            </Translate>
                        </div>
                        {emailMessage}
                        <button className={ this.getSubmitButtonClasses() } onClick={this.submitEmailAddress}>
                            <Translate id="sub-titles.sub-title-3.submit" />
                        </button>
                    </div>
                    <div className="align-center">
                        <Translate id="sub-titles.sub-title-3.after" />
                    </div>
                    <button className="generic-location-button add-pulse-animation" onClick={() => this.htmlRedirect(`${getBaseName()}/accounts/login/`)}>
                        <Translate id="login-button" />
                    </button>
                </div>


                <div className="parallax-wrapper" style={{backgroundImage: `url(${extraBoyImg}.${getExtension()})`}}>
                    <div className={ isBrowser ? "browser-wrapper" : "mobile-wrapper" }>


                        <div className="loyalty-wrapper">
                            <div className="generic-heading">
                                <Translate id="how-does-it-work" />
                            </div>
                            <div className="generic-section">
                                <Translate id="steps.before" />
                            </div>


                            <ul>
                                <li><Translate id="steps.step-1" /></li>
                                <li><Translate id="steps.step-2" /></li>
                                <li>
                                    <Translate id="steps.step-3.main" />
                                    <ul>
                                        <li>
                                            <span className="blue-text">
                                                <Translate id="steps.step-3.sub-list-1.timeframe" />
                                            </span>


                                            <Translate id="steps.step-3.sub-list-1.earn" />


                                            <span className="blue-text">
                                                <Translate id="steps.step-3.sub-list-1.amount" />
                                            </span>
                                            <Translate id="steps.step-3.sub-list-1.end" />
                                        </li>
                                        <ul>
                                            <li><Translate id="steps.step-3.sub-list-1.footnote" /></li>
                                        </ul>


                                    <li>
                                            <span className="blue-text">
                                                <Translate id="steps.step-3.sub-list-2.timeframe" />
                                            </span>


                                            <Translate id="steps.step-3.sub-list-2.earn" />


                                            <span className="blue-text">
                                                <Translate id="steps.step-3.sub-list-2.amount" />
                                            </span>
                                            <Translate id="steps.step-3.sub-list-2.end" />
                                        </li>
                                        <ul>
                                            <li><Translate id="steps.step-3.sub-list-2.footnote" /></li>
                                        </ul>
                                    </ul>
                                    <div>
                                        <span style={{fontSize:"0.8em"}}><Translate id="steps.step-3.footnote" /></span>
                                    </div>
                                    <div>
                                        <span style={{fontSize:"0.8em"}}><Translate id="steps.step-3.footnote-2" /></span>
                                    </div>
                                </li>
                                <li>
                                    <Translate id="steps.step-4" />
                                </li>


                                <div className="align-center">


                                    <DynamicImg
                                        imageName={LoyaltyLinkCredsImage}
                                        fallbackExt='png'
                                        imgClass="loyalty-image"
                                        alt="Loyalty Credentials Screenshot"
                                        width="100%"
                                        height="auto"
                                    />
                                </div>


                                <li>
                                    <Translate id="steps.step-5" />
                                </li>


                                <div className="align-center">
                                    <DynamicImg
                                        imageName={LoyaltyRedeemImage}
                                        fallbackExt='png'
                                        imgClass="loyalty-image"
                                        alt="Redeem Points Screenshot"
                                        width="100%"
                                        height="auto"
                                    />
                                </div>
                            </ul>
                        </div>


                        <Translate id="steps.after" />


                        <button className="generic-location-button add-pulse-animation" onClick={() => this.htmlRedirect(`${getBaseName()}/accounts/login/`)}>
                            <Translate id="login-button" />
                        </button>
                    </div>
                </div>


                <div className={ isBrowser ? "browser-wrapper" : "mobile-wrapper" }>
                    <div className="generic-heading">
                        <Translate id="section-2.take-ctrl" />
                        <span className="blue-text">Ctrl</span>
                    </div>


                    <div className="generic-section">
                        <div className="align-center">
                            <Translate id="section-2.body-1.before" />


                            <span className="blue-text">Ctrl V</span>


                            <Translate id="section-2.body-1.customers" />
                            <span className="blue-text">
                                <Translate id="section-2.body-1.end" />
                            </span>.
                        </div>
                    </div>
                    <DynamicImg
                        imageName={PortalDashboardImage}
                        fallbackExt='png'
                        imgClass="generic-image"
                        alt="Portal Dashboard"
                        width="100%"
                        height="auto"
                    />


                    <div className="generic-section">


                        <Translate id="section-2.body-2.start" />


                        <span className="blue-text">
                            <Translate id="section-2.body-2.google" />
                        </span>


                        <Translate id="section-2.body-2.or" />


                        <span className="blue-text">
                            <Translate id="section-2.body-2.facebook" />
                        </span>


                        <Translate id="section-2.body-2.credentials" />


                        <span className="blue-text">
                            <Translate id="section-2.body-2.waiver" />
                        </span>


                        <Translate id="section-2.body-2.access" />
                    </div>
                </div>
                <div className={isBrowser ? "browser-section" : "mobile-wrapper"}>
                    <span className="anchor" id="how-to"></span>
                    <div className={isBrowser ? "account-video-center" : ""}>
                        <div className="generic-heading">
                            <div className="align-center">
                                <Translate id="section-2.body-3.how-to" />
                            </div>
                            <div className={isBrowser ? "birthday-video" : ""}>
                                <Video video="0eplid10wOE" />
                            </div>
                        </div>
                    </div>
                </div>
                <ReCaptcha
                    sitekey='6LchmVwaAAAAAJMmwe8VbW-pCmj0ou2ztqTgqorL'
                    action='loyalty_page'
                    verifyCallback={this.onCaptchaResponse}
                    ref={ref => this.recaptcha = ref}
                    expiredCallback={this.onCaptchaExpired} // this isn't a param according to documentation, but might be implemented
                />
            </div>
        );
    }
}


export default withLocalize(Loyalty);